// import JsonData from './data.json'
import React from "react";
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.JsonData = []
    this.currentLanguage = 'de';
    this.state = { data: this.JsonData[this.currentLanguage],
      languages:["de"],
      overlay: [false, false, false, false, false, false],
      overlayPageIndex: 0,
      movement: 0,
      touchMovement: 'translateX(0px)',
      videoElements: [],
      touchStart: 0,
      touchEnd: 0
    };
    this.languageChange = this.languageChange.bind(this);
    this.runningVideo = null;
    this.currentSlide = 0;
    this.references = {};
    this.contents = [];
    this.enableMove = false;
    this.videoReference = false;
  }
  componentDidMount() {
    var path = './data.json';
    fetch(path)
        .then(res => res.json())
        .then(
            (result) => {
              this.JsonData = result;
              var data = this.JsonData;
              Object.keys(data).forEach(function(lang,index) {
                data[lang].content.map((content, i) => {
                  return content.content.map((content, j) => {
                    if (content.type === 'mp4') {
                      this.JsonData[lang].content[i].content[j].ref = React.createRef();
                    }
                    return true;
                  }, this)
                }, this)
              }, this)

              this.setState({
                data: this.JsonData[this.currentLanguage],
                languages: Object.keys(this.JsonData)
              });
            }
        )
  }

  handleTouchStart(e) {
    this.setState({touchStart: e.targetTouches[0].clientX})
  }

  handleTouchMove(e) {
    this.setState({touchEnd: e.targetTouches[0].clientX})

    if (this.state.touchStart !== 0) {
      var move = -1 * (this.state.touchStart - this.state.touchEnd);
      this.setState({touchMovement: 'translateX(' + move + 'px)'});
      this.enableMove = true;
    }
  }
  handleTouchEnd() {
    if (this.enableMove) {
      var next = this.currentSlide + 1;
      this.setState({touchMovement: 'translateX(0px)'});

      if (this.contents.content.length > next && this.state.touchStart - this.state.touchEnd > 150) {
        this.setMovement(next)
      }

      var previoues = this.currentSlide - 1;
      if (previoues > -1 && this.state.touchStart - this.state.touchEnd < -150) {
        this.setMovement(previoues)
      }
    }
    this.setState({touchEnd: 0})
    this.enableMove = false;
  }

  languageChange = (language) => {
    var data = this.JsonData[language];
    this.currentLanguage = language;
    this.setState({data: data});

    this.setMovement(this.currentSlide)
  }

  contentItem = (content, index) => {
    var hexagonClassName = 'hexagon-wrap hexagon-'+index;

    return <div key={index}>
      <div className={hexagonClassName} onClick={function (){
        var overlay = this.state.overlay;
        overlay[index] = true;
        this.setState({overlay: overlay, overlayPageIndex: 0});
        this.contents = content;
        this.setMovement(0);
      }.bind(this)}>
        <div className="hexagon-bg"></div>
        <div className="hexagon-title" dangerouslySetInnerHTML={{__html: content.title}}></div>
      </div>
      <div className={this.state.overlay[index] === true ?'fadeIn overlay':'fadeOut overlay'}>
        <div className="background"/>
        <div className="closeOverlay" onClick={function (){

          if (this.contents.content[this.state.overlay[index]] && this.contents.content[this.state.overlay[index]].ref) {
            this.contents.content[this.state.overlay[index]].ref.current.pause();
            this.contents.content[this.state.overlay[index]].ref.current.currentTime = 0;
          }

          var overlay = this.state.overlay;
          overlay[index] = false;

          this.setState({overlay: overlay});
          setTimeout(
              this.setMovement(0, false),
              250
          )

        }.bind(this)}><img src="images/close.svg" alt="" /></div>
        {this.contentOverlay(content, index)}
      </div>
    </div>
  }

  contentOverlay = (content, index) => {
    return  <div className="overlayContent">
      {this.previousOverlayPage(content)}
      <div className="innerContent-wrap">
        <div className="innerContent-inner" style={{transform: this.state.movement}}>
          <div className="innerContent-innerswipable" onTouchStart={touchStartEvent => this.handleTouchStart(touchStartEvent)}
               onTouchMove={touchMoveEvent => this.handleTouchMove(touchMoveEvent)}
               onTouchEnd={() => this.handleTouchEnd()}
               style={{transform: this.state.touchMovement}}
          >
            {content.content.map(function (content, index){return this.overlayContentItem(content, index)}.bind(this))}
          </div>
        </div>
      </div>
      {this.nextOverlayPage(content)}
      {this.pagination(content)}
    </div>
  }

  pagination = (content) => {
    if (content.content.length > 1) {
      return <div className="pagination">{content.content.map(function (content, index){return this.paginationItem(index)}.bind(this))}</div>
    }
    return '';
  }

  setMovement(step, enableVideo = true) {
    this.currentSlide = step;
    var movement = "translateX(" + -1 * step * 100 + "%)";
    this.setState({overlayPageIndex: step});
    this.setState({movement: movement});

    if (enableVideo) {
      if (this.videoReference && this.contents.content[step].ref) {
        this.contents.content[step].ref.current.pause();
        this.contents.content[step].ref.current.currentTime = 0;
      }

      if (this.contents.content[step].type === 'mp4') {
        this.videoReference = this.contents.content[step].ref;
        this.videoReference.current.play();
        this.setState({videoRunning: true});
      } else {
        this.setState({videoRunning: false});
      }
    } else {
      this.setState({videoRunning: false});
    }
  }

  paginationItem = (index) => {
    var classes = 'pagination-item';
    if (index === this.state.overlayPageIndex) {
      classes += ' active'
    }

    return <div onClick={function (){
      this.setMovement(index);
    }.bind(this)} className={classes} />
  }

  previousOverlayPage = (content) => {
    if (this.state.overlayPageIndex > 0) {
      return <div className="side-arrows previous" onClick={function (){
        var index = this.state.overlayPageIndex - 1
        this.setMovement(index);
      }.bind(this)}><img src="images/arrow-left.svg" alt="" /></div>;
    }

    return '';
  }

  nextOverlayPage = (content) => {
    if (content.content.length > this.state.overlayPageIndex + 1) {
      return <div className="side-arrows next" onClick={function (){
        var index = this.state.overlayPageIndex + 1
        this.setMovement(index);
      }.bind(this)}><img src="images/arrow-right.svg" alt="" /></div>;
    }

    return '';
  }

  overlayContentItem = (content, index) => {
    switch (content.type) {
      case 'text':
        return this.overlayContentItemText(content, index);
      case 'image':
        return this.overlayContentItemImage(content, index);
      case 'youtube':
        return this.overlayContentItemYoutube(content, index);
      case 'mp4':
        return this.overlayContentItemMp4(content, index);
      default:
        return '';
    }
  }

  overlayContentItemMp4 = (content, index) => {
    var transform = "translateX("+ index * 100 +"%)";
    var key = 'videomp4-'+index;

    var classname = 'iframe-wrap';
    if(content.caption){
      classname += ' has_caption';
    }

    return <div key={key} style={{transform: transform}}>
      <div className={classname}>
        <div class={this.state.videoRunning === true ?'hidden play-button':' play-button'} onClick={() => {this.videoReference.current.play(); this.setState({videoRunning: true});}}>
          <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                  clip-rule="evenodd"></path>
          </svg></div>
        <div className="videoFrame">
          <div>
            <video width="1280" height="720" muted ref={content.ref} onClick={() => {this.videoReference.current.pause(); this.setState({videoRunning: false});}} >
              <source src={content.link} title={content.title} type="video/mp4" />
            </video>
            <div className="imageCaption"><caption dangerouslySetInnerHTML={{__html: content.caption}} /></div>
          </div>
        </div>
      </div>
    </div>
  }

  overlayContentItemYoutube = (content, index) => {
    var transform = "translateX("+ index * 100 +"%)"
    var key = 'videoyotube-'+index;

    return <div key={key} style={{transform: transform}}>
      <div className="iframe-wrap">
        <iframe
            title={content.title}
            src={content.link}
            width="1280"
            height="720"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      </div>
    </div>
  }

  overlayContentItemImage = (content, index) => {
    var transform = "translateX("+ index * 100 +"%)";
    var key = 'image-'+index;
    var classname = 'overlay-text';
    if(content.caption){
      classname += ' has_caption';
    }

    return <div key={key} className={classname} style={{transform: transform}}>
      <div className="iframe-wrap">
        <div className="image">
          <div>
            <img src={content.link} alt={content.title} />
            <div className="imageCaption"><caption dangerouslySetInnerHTML={{__html: content.caption}} /></div>
          </div>
        </div>
      </div>
    </div>
  }

  overlayContentItemText = (content, index) => {
    var transform = "translateX("+ index * 100 +"%)";
    var key = 'text-'+index;

    return <div key={key} className="overlay-text" style={{transform: transform}}>
      <div className="inner-text-ce">
        <h3>{content.title}</h3>
        <div className="text" dangerouslySetInnerHTML={{__html: content.text}}></div>
      </div>
    </div>
  }

  render() {
    if (!this.state.data) {
      return '';
    }

    return <div className="background-wrapper" style={{ backgroundImage: `url(${this.state.data.background})` }}>
      <div className="contentPart">
        <h1 className="pageTitle">{this.state.data.title}</h1>
        <h2 className="pageSubtitle">{this.state.data.subtitle} <img src="images/legend.svg" className="legend-icon" alt="" /></h2>
        <div className="Board">
          {this.state.data.content.map(function (content, index){return this.contentItem(content, index)}.bind(this))}
        </div>
      </div>
      <div className="languages">{this.state.languages.map(function (language){
            var className = '';
            if (this.currentLanguage === language) {
              className += " active";
            }
            return <button key={language} data-param={language} className={className} onClick={() => {this.languageChange(language)}}>{language}</button>
      }.bind(this)
      )}</div>
    </div>
  }
}

export default App;
